{
  "menu.membership": "Membership",
  "menu.purchase": "Purchase",
  "menu.residential": "Residential",
  "menu.proxytester": "Proxy Tester",
  "menu.myorders": "My Orders",
  "menu.profile": "Profile",

  "menu.profile.confirmlogout": "Confirm to log out?",
  "menu.profile.logout": "Logout",
  "menu.profile.changeyouremail": "Change Your Email",
  "menu.profile.linkyouremail": "Link Your Email",
  "menu.profile.verification": "Haven't received a verification code in your email?",
  "menu.profile.clickhereto": "Click here to",
  "menu.profile.resend": "re-send",
  "menu.profile.theemail": "the email.",
  "menu.profile.link": "Link",
  "menu.profile.verify1": "An email with instructions to verify your email address has been sent to you.",
  "menu.profile.verify2": "Please verify your email address to activate your account.",
  "menu.profile.resend.success": "Resend successfully",
  "menu.profile.remail.unsuccess": "Email changed unsuccessfully",
  "menu.profile.remail.success": "Email changed successfully",

  "menu.extra.community": "Community/Tech Support",

  "membership.subscribe": "Subscribe",
  "membership.desc1.c1": "Free Data Worth $46",
  "membership.desc1.c2": "Exclusive Discount Code",
  "membership.desc1.c3": "Dragon Family Product Raffle",
  "membership.desc1.c4": "Exclusive Partnership Giveaway",
  "membership.desc2.c1": "Free Data Worth $108",
  "membership.desc2.c2": "Exclusive Discount Code",
  "membership.desc2.c3": "Dragon Family Product Raffle With A Higher Chance of Winning",
  "membership.desc2.c4": "Exclusive Partnership Giveaway",

  "membership.plan1.name": "Gold Membership",
  "membership.plan2.name": "Diamond Membership",

  "purchase.totalamount": "Total amount",
  "purchase.paymentmethod": "Payment method",
  "purchase.buy": "Buy",
  "purchase.apply": "Apply",
  "purchase.discountcode": "Discount Code",
  "purchase.product": "Product",
  "purchase.enterprise.title": "If you need an enterprise package, please contact",

  "purchase.buy.reason.code": "Unavailable code",
  "purchase.buy.reason.goods": "Please select goods",
  "purchase.buy.reason.paymethod": "Please select a payment method",
  "purchase.buy.reason.success": "Apply successfully!",
  "purchase.buy.notify": "The product you purchase is a digital good and our proxy service will be delivered instantly on your dashboard. It means any order placed can not be returned nor refunded. All sales are 100% final. Please make sure you agree with our refund policy before you decide to make a purchase. You're always welcome to open a ticket on our discord server if you have any questions.",
  "purchase.discount.reason.goods": "Please select product first",
  "purchase.discount.reason.code": "Please input code",

  "common.warning": "Warning",
  "common.agree": "Agree",
  "common.confirm": "Confirm",
  "common.cancel": "Cancel",
  "common.save": "Save",
  "common.reason.unknown": "Unknown error",
  "common.form.pleaseselect": "Please select",
  "common.form.pleaseinput": "Please input",
  "common.form.isee": "I see.",
  "common.overview": "Overview",

  "residential.panel.notimelimit": "No time limit",
  "residential.panel.expire": "Expire",
  "residential.panel.resetpassword": "Reset Password",
  "residential.panel.remaining": "Remaining",
  "residential.panel.used": "Used",
  "residential.panel.alltime": "All-time",
  "residential.panel.week": "Week",
  "residential.panel.month": "Month",

  "residential.panel.reason.planName": "Invalid plan name!",
  "residential.panel.reason.planId": "Invalid plan id!",
  "residential.panel.reason.success": "Changes will take effect later... Please do not test your proxy immediately",

  "residential.proxy.generateproxies": "Generate Proxies",
  "residential.proxy.shuffle": "Shuffle",
  "residential.proxy.copy": "Copy",
  "residential.proxy.download": "Download",
  "residential.proxy.generatedsuccessfully": "Generated successfully",
  "residential.proxy.savesuccessfully!": "Save successfully",
  "residential.proxy.copysuccess": "Copy success",

  "residential.proxy.gen.usedefaultprofile": "Use the Default profile",
  "residential.proxy.gen.defaultprofile": "Default profile",
  "residential.proxy.gen.profile": "Plan",
  "residential.proxy.gen.plan": "Plan",
  "residential.proxy.gen.country": "Country",
  "residential.proxy.gen.rotate": "Rotate",
  "residential.proxy.gen.quantity": "Quantity",
  "residential.proxy.gen.saveasdefault": "Save as default",
  "residential.proxy.gen.generate": "Generate",
  "residential.proxy.gen.rotate.notrotate": "Not Rotate",
  "residential.proxy.gen.rotate.rotateperrequest": "Rotate Per Request",
  "residential.proxy.gen.rotate.confirmdelete": "Confirm delete?",

  "tester.title": "Proxy Tester",
  "tester.desc": "Dragon Proxies Tester is developed by the Dragon team and is free to download for all Dragon users. 20+ websites to test & you can also choose custom sites, supports HTTP/HTTPS/Sock5 protocols.",

  "order.title": "My Orders",
  "order.purchase": "Purchase",
  "order.table.time": "Time",
  "order.table.plan": "Plan",
  "order.table.product": "Product",
  "order.table.paymentmethod": "Payment Method",
  "order.table.paid": "Paid",
  "order.table.action": "Action",
  "order.action.invoice": "Invoice",
  "order.action.download": "Download",
  "order.reason.deliveryinprogress": "Delivery in progress",
  "order.action.expired": "Expired",

  "profile.profile": "Profile",
  "profile.form.firstname": "First Name",
  "profile.form.lastname": "Last Name",
  "profile.form.phone": "Phone Number",
  "profile.form.address": "Address",
  "profile.form.address2": "Detailed Address",
  "profile.form.country": "Country",
  "profile.form.city": "City",
  "profile.form.zipcode": "Zip Code",
  "profile.form.companyname": "Company Name",
  "profile.form.companyurl": "Company Url",

  "header.notifications": "Notifications",

  "footer.policy": "Privacy Policy",
  "footer.team": "Terms and Conditions",
  "footer.copyright": "Part of the Dragon Power Inc family"
}
